<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  :plan1-active="plan1Active"
                  :plan1-details="plan1Details"
                  :plan2-title="plan2Title"
                  :plan2-list="plan2List"
                  :plan3-list="plan3List"
                  />
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Plan1',
  components: {
   PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '解决方案',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/07.jpg'), txt: '5G专网', txt2: '校内校外', txt3: '随时随地访问校内资源', jumpUrl: 'btn'},
      ],
      plan1Active: Public.plan1().routerName,
      // 当前方案概述详情
      plan1Details:Public.plan1(),
      plan2Title: {
        title: '我们的经验', txt: 'Program Overview'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/28.fw.png'), title: '网络体验舒心', txt: '用户侧无感入网校外、校内随时接入，实时访问'},
        {img: require('@/assets/images/27.fw.png'), title: '数据安全放心', txt: '网络身份验证安全，无侵入可能专用UPF，用户数据不出专网结合校园统一认证，实现双层防护'},
        {img: require('@/assets/images/26.fw.png'), title: '网络管理省心', txt: '运营商+安冉云统一管理运营商维护团队和校内安冉云团队一起维护'},
        {img: require('@/assets/images/25.fw.png'), title: '业务应用会心', txt: '校内应用和资源区分教师和学生校内优质资源，分发推荐提高利用效率'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/31.fw.png'), title: '河南大学', txt: '国家“双一流”建设高校，入选国家“111计划”、中西部高校基础能力建设工程'},
        {img: require('@/assets/images/30.fw.png'), title: '河南师范大学', txt: '是国家中西部高等教育振兴计划支持高校、国家“111计划”实施高校'},
        {img: require('@/assets/images/school/kf.jpg'), title: '河南开放大学', txt: '前身是创办于1979年的河南广播电视大学，2020年11月正式更名为河南开放大学'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
